import { LoadingButton } from '@mui/lab'
import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PaperContainer from 'components/UI/PaperContainer'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { appFormShopRequestDataSelector } from 'redux/reducers/client/allState/clientSelectors'
import { updateShopRequestApplicationThunk } from 'redux/reducers/client/allState/clientThunks'
import { handleFloatInput } from 'utils/common/forms'

interface IFormProps {
  prepayment: number
}

const CreditInfoPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { status, appFormFlowData } = useSelector(userFlowSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormProps>({ mode: 'onChange' })

  if (!appFormFlowData || !shopRequestData) return <Typography>{t('common.noData')}</Typography>

  const { flow_data } = appFormFlowData
  const { credit_policy, application, shop, product_name, product_id, product_price } =
    shopRequestData
  const { is_prepayment, prepayment_min, prepayment_max } = credit_policy

  if (!application) return <Typography>{t('client.applicationForm.creditInfo.noApplication')}</Typography>

  const minValue = application.loan_amount * prepayment_min
  const maxValue = application.loan_amount * prepayment_max

  const submitHandler = (data: IFormProps) => {
    dispatch(updateShopRequestApplicationThunk(application.id, data))
  }

  const handleNext = async (currentStep: number) => {
    if (minValue === maxValue) {
      const isSuccess = await dispatch(
        updateShopRequestApplicationThunk(application.id, { prepayment: minValue })
      )

      if (isSuccess as unknown) dispatch(updateAppFormUserFlowThunk({ step: currentStep + 1 }))
    } else {
      dispatch(updateAppFormUserFlowThunk({ step: currentStep + 1 }))
    }
  }

  return (
    <PaperContainer>
      <Typography variant="h2" align="center" mb={4}>
        {t('client.applicationForm.steps.credit')}
      </Typography>
      <Box sx={{ '& span': { fontWeight: 600 }, mb: 4 }}>
        <Typography>
          <span>{t('client.applicationForm.creditInfo.productName')}</span> {product_name}
        </Typography>
        <Typography>
          <span>{t('client.applicationForm.creditInfo.shopName')}</span> {shop.name}
        </Typography>
        <Typography>
          <span>{t('client.applicationForm.creditInfo.shopSite')}</span> {shop.url}
        </Typography>
        {product_id && (
          <Typography>
            <span>{t('client.applicationForm.creditInfo.article')}</span>
            {product_id}
          </Typography>
        )}
        <Typography>
          <span>{t('client.applicationForm.creditInfo.price')}</span> {`${product_price}$`}
        </Typography>
      </Box>
      {application.discount && (
        <Box sx={{ '& span': { fontWeight: 600 }, mb: 4 }}>
          <Typography variant="h3" mb={1}>
            {t('client.applicationForm.creditInfo.discountInfo')}
          </Typography>
          <Typography>
            {t('client.applicationForm.creditInfo.discountValidLoan')} <span>{`${application.discount * 100}%`}</span>
          </Typography>
        </Box>
      )}
      {is_prepayment && !!application.prepayment && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3">
            {`${t('client.applicationForm.creditInfo.prepayment')} ${application.prepayment}$`}
          </Typography>
        </Box>
      )}
      {is_prepayment && !application.prepayment && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Box
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
              maxWidth: { xs: '100%', md: '600px' }
            }}
          >
            <Typography align="center" variant="h3" mb={2}>
              {minValue === maxValue
                ? `${t('client.applicationForm.creditInfo.prepaymentAmount')} ${minValue.toFixed(2)}$`
                : `${t('client.applicationForm.creditInfo.enterPrepaymentAmount')} ${minValue.toFixed(2)}-${maxValue.toFixed(2)}$`}
            </Typography>
            {minValue !== maxValue && (
              <>
                <TextField
                  {...register('prepayment', {
                    required: 'Field required',
                    valueAsNumber: true,
                    value: Number(minValue.toFixed(2)),
                    min: { value: minValue, message: `${t('client.applicationForm.creditInfo.warn.minValue')} ${Number(minValue.toFixed(2))}` },
                    max: { value: maxValue, message: `${t('client.applicationForm.creditInfo.warn.maxValue')} ${Number(maxValue.toFixed(2))}` }
                  })}
                  onChange={handleFloatInput}
                  label={t('client.applicationForm.creditInfo.label.prepaymentAmount')}
                  disabled={minValue === maxValue}
                  error={!!errors.prepayment}
                  helperText={errors.prepayment?.message}
                />
                <Button type="submit" sx={{ alignSelf: 'center' }} variant="contained">
                  {t('client.applicationForm.creditInfo.button.save')}
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ display: 'flex' }}>
        <LoadingButton
          onClick={() => handleNext(flow_data.step)}
          loading={status === 'load'}
          disabled={is_prepayment && !application.prepayment && minValue !== maxValue}
          variant="contained"
          sx={{ ml: 'auto' }}
        >
          {t('client.applicationForm.creditInfo.button.next')}
        </LoadingButton>
      </Box>
    </PaperContainer>
  )
}

export default CreditInfoPage
